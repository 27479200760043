import React from "react";

import { useState } from "react";
import UserSelect from "../menu/Form/select/UserSelect";
import { Button, Col, Collapse, Divider, Flex, Input, Row, Select, Typography } from "antd";
import { ArtistRep } from "../../../data/models/artist-rep";
const { Text } = Typography;

type Props = {
    loading: boolean,
    contract: any,
    contracts: Array<ArtistRep>,
    setContracts: Function,
    hasNewContract: boolean,
    index: number,
    setHasNewContract: Function,
}

const RepresentationContract = ({ loading, contract, contracts, setContracts, hasNewContract, index, setHasNewContract }: Props) => {
    const [dealerSelected, setDealerSelected] = useState<any>({ username: contract?.dealer_username, id: contract?.rep_id });
    const [newContract, setNewContract] = useState<ArtistRep>(contract as ArtistRep)
    const [isOpen, setIsOpen] = useState(true);
    const [selectedType, setSelectedType] = useState(contract.rep_type.toString() || "4")
    const contractTypes = [
        { label: "artwork representation", value: "4" },
        { label: "artist management", value: "3" }
    ]

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const saveContract = () => {
        let newContracts = [...contracts]
        newContracts.splice(index, 1);
        setContracts([...newContracts,
        {
            ...newContract,
            dealer_profile: dealerSelected.profile_name,
            dealer_username: dealerSelected.username,
            rep_id: dealerSelected.id,
            editing: false,
            rep_type: parseInt(selectedType)
        }]);
        setHasNewContract(false)
    }
    const cancel = () => {
        let newContracts = [...contracts]

        newContracts[index].editing = false;
        if (hasNewContract) newContracts.splice(index, 1);
        setHasNewContract(false)
        setContracts([...newContracts])

    }
    const allowEdit = (e) => {
        e.stopPropagation();

        if (!isOpen) {
            setIsOpen(true);
        }
        let newContracts = [...contracts]
        newContracts[index].editing = true;
        setContracts([...newContracts])
    }

    return (
        <>
            <Collapse
                className="contract-collapse mt-3"
                activeKey={isOpen ? index : ''}
                defaultActiveKey={isOpen ? index : ''} onChange={toggleCollapse}
                collapsible='header'
                style={{ alignItems: 'center' }}
                items={[
                    {
                        key: index,
                        label: <Row gutter={8} style={{ alignItems: 'center' }}>
                            <Col flex="auto">
                                <Divider className="my-0" orientation="left" orientationMargin="0">{Object.keys(dealerSelected).length > 1 ? `${dealerSelected.username} ` : `Dealer ${index + 1}`}</Divider>
                            </Col>
                            {!contract.editing &&
                                <Col flex="116px">
                                    <Button
                                        className='mt-0'
                                        type="primary" onClick={allowEdit}>Edit {contract.rep_type === 4 ? 'Dealer' : 'Manager'}</Button>
                                </Col>
                            }
                        </Row>,
                        children:
                            <>
                                <UserSelect update={loading} className='mb-5' setData={setDealerSelected} data={dealerSelected} selectedUser={dealerSelected.username} route="/api-users?type=4" disableField={!contract.editing} />
                                {
                                    Object.keys(dealerSelected).length > 1 &&
                                    <>
                                        <Row gutter={8}>
                                            <Col span={24} md={12}>
                                                <Text>commission value %</Text>
                                                <Input disabled={(dealerSelected && Object.keys(dealerSelected).length === 0) || !contract.editing} className='mb-5' value={newContract?.fee || ''}
                                                    onChange={(e) => setNewContract({ ...newContract, fee: parseInt(e.target.value) })}
                                                    placeholder="Ex: 10" />
                                            </Col>
                                            <Col span={24} md={12}>
                                                <Text>Contract type</Text>
                                                <Select
                                                    disabled={!contract.editing}
                                                    placeholder="Select contract type"
                                                    style={{ width: '100%' }}
                                                    defaultValue={selectedType}
                                                    onChange={(e) => setSelectedType(e)}
                                                    options={contractTypes}
                                                />
                                            </Col>
                                        </Row>
                                        {contract?.editing &&
                                            <Flex gap="middle" align="start" vertical>
                                                <Flex className='w-full' justify="space-between" align="center">
                                                    <Button danger onClick={cancel}>
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className='mt-4'
                                                        onClick={saveContract}
                                                        type="primary">Save contract</Button>
                                                </Flex>
                                            </Flex>
                                        }
                                    </>
                                }
                            </>
                        ,
                    },
                ]}
            />

        </>
    )
};


export default RepresentationContract;